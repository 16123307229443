import Bugsnag from '@bugsnag/js';
import { INPAttribution, onINP } from 'web-vitals/attribution';

const threshold = 200;

type reportType = {
  value: number;
  processingDuration: number;
  interactionTarget: string;
  interactionType: string;
  interaction: INPAttribution;
};

onINP(({ value, attribution }) => {
  if (value > threshold) {
    const { processingDuration, interactionTarget, interactionType } =
      attribution;

    const report = {
      value,
      processingDuration,
      interactionTarget,
      interactionType,
      interaction: attribution
    };

    logIntoBugsnag(report);
  }
});

const logIntoBugsnag = (report: reportType) => {
  if (!Bugsnag.isStarted()) {
    return;
  }

  Bugsnag.notify(
    new Error(`High INP value detected: ${report.value}`),
    (event) => {
      event.addMetadata('web-vitals', report);
    }
  );
};
